import React, { useEffect } from 'react';
import { StyleSheet, View, Image, Animated, Easing } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import Button from '../components/Button';
import Screen from '../components/Screen';
import routes from '../navigation/routes';

export default function WelcomeScreen ({ navigation }) {

  useEffect(() => {
    startImageRotateFunction();
  }, []);

  let rotateValueHolder = new Animated.Value(0);
  const startImageRotateFunction = () => {
    rotateValueHolder.setValue(0);
    Animated.timing(rotateValueHolder, {
      toValue: 1,
      duration: 3000,
      easing: Easing.exp,
      useNativeDriver: false,
    }).start();
  };
  const RotateData = rotateValueHolder.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '30deg'],
  });
  const RotateLeft = rotateValueHolder.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-30deg'],
  });
  const textSize = rotateValueHolder.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 50]
  })

  return (    
      <Screen>
        <LinearGradient
          colors={["red", "white"]}
          style={{ flex: 1, padding: 20}}
        >
          <View style={styles.imageContainer}>
            <Animated.Image source={require('../assets/images/doorB.png')} style={{height: 150, width: 80, transform: [{ rotate: RotateLeft }], marginRight: -10}} />
            <Image source={require('../assets/images/doorA.png')} style={{height: 230, width: 100, zIndex: 1}} />
            <Animated.Image source={require('../assets/images/doorC.png')} style={{height: 150, width: 80, transform: [{ rotate: RotateData }], marginLeft: -10}} />
          </View>
          <Animated.Text style={{textAlign: "center", fontSize: textSize, fontWeight: "bold", marginTop: -20, marginBottom: 20}}>Door A Game</Animated.Text>

          <Button title={"1 Spieler"} onPress={() => navigation.navigate(routes.GAME, {player: 1})} />
          <Button title={"2 Spieler"} onPress={() => navigation.navigate(routes.GAME, {player: 2})} />

          <Button title={"Regeln"} onPress={() => navigation.navigate(routes.RULE)} />
          
        </LinearGradient>
      </Screen>
  );
}

const styles = StyleSheet.create({
  imageContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    paddingTop: 30, 
    //padding: 20,
    flexDirection: "row",
  },
});

/*

<Button title={"info"} onPress={() => navigation.navigate(routes.ABOUT)} />

*/