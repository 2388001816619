import React from 'react';
import { StyleSheet, Image } from 'react-native';

function HeaderBranding ({length = 60, style}) {
  return (  
    <Image style={{...styles.image, width: length, height: length, ...style}} source={require('../assets/images/icon.png')} /> 
  ); 
}

const styles = StyleSheet.create({
  image: {
    height: 50, 
    marginRight: 10,
  },
});

export default HeaderBranding;

