import React, { useState, useEffect } from "react";
import * as SplashScreen from 'expo-splash-screen';

import StackNavigator from "./StackNavigator";

export default function CheckAuthentification() {

  useEffect(() => {
    (async () => {
      await SplashScreen.preventAutoHideAsync();      
      
      //Maybe some Statistics

      //Maybe some rights

      await SplashScreen.hideAsync();
    })();
  }, []);

  
  return (
    <StackNavigator />
  );
}
