import React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';


export default function Screen ({children, backgroundColor, scroll = false, style}) {

  return (    
    <>
      {!scroll ?
        <SafeAreaView style={{flex: 1, backgroundColor: backgroundColor, ...style}}>
          {children}
        </SafeAreaView> 
      : 
        <ScrollView style={{flex: 1, backgroundColor: backgroundColor, ...style}}>
          {children}
        </ScrollView>
      }
    </>
    
  );
}
