import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';


export default function AboutScreen (props) {

  
  return (    
    <View>
      <Text>$0</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    
  },
});
