import React from "react";

import CheckAuthentification from "./app/navigation/CheckAuthentification";


//    .----------------------------------------------------.
//    | Project Name:     DoorAGame                        |
//    | Name:             Tür A Spiel                      |
//    | Author:           Christoph Leuthner               |
//    | Created:          03/2022                          |
//    | FirstRelease:     ?                                |
//    | Copyright:        Christoph Leuthner               |
//    | Special Thanks:   Dora (Inventet Game)             |
//    | Framework:        Expo / EAS                       |
//    | Language:         JavaScript                       |
//    | Backend:          None                             |
//    *----------------------------------------------------*

export default function App() {
  return (
    <CheckAuthentification />
  );
}
