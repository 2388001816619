
export default {
  version: "1.0.0",

  cardBackground: require('../assets/cards/background.jpg'),

  cards: [
    {
      value: 2,
      color: "hearts",
      imagePath: require('../assets/cards/2_of_hearts.png'),
      name: "Herz Zwei",
    },
    {
      value: 2,
      color: "diamonds",
      imagePath: require('../assets/cards/2_of_diamonds.png'),
      name: "Karo Zwei",
    },
    {
      value: 2,
      color: "clubs",
      imagePath: require('../assets/cards/2_of_clubs.png'),
      name: "Kreuz Zwei",
    },
    {
      value: 2,
      color: "spades",
      imagePath: require('../assets/cards/2_of_spades.png'),
      name: "Pik Zwei",
    },
    {
      value: 3,
      color: "hearts",
      imagePath: require('../assets/cards/3_of_hearts.png'),
      name: "Herz Drei",
    },
    {
      value: 3,
      color: "diamonds",
      imagePath: require('../assets/cards/3_of_diamonds.png'),
      name: "Karo Drei",
    },
    {
      value: 3,
      color: "clubs",
      imagePath: require('../assets/cards/3_of_clubs.png'),
      name: "Kreuz Drei",
    },
    {
      value: 3,
      color: "spades",
      imagePath: require('../assets/cards/3_of_spades.png'),
      name: "Pik Drei",
    },
    {
      value: 4,
      color: "hearts",
      imagePath: require('../assets/cards/4_of_hearts.png'),
      name: "Herz Vier",
    },
    {
      value: 4,
      color: "diamonds",
      imagePath: require('../assets/cards/4_of_diamonds.png'),
      name: "Karo Vier",
    },
    {
      value: 4,
      color: "clubs",
      imagePath: require('../assets/cards/4_of_clubs.png'),
      name: "Kreuz Vier",
    },
    {
      value: 4,
      color: "spades",
      imagePath: require('../assets/cards/4_of_spades.png'),
      name: "Pik Vier",
    },
    {
      value: 5,
      color: "hearts",
      imagePath: require('../assets/cards/5_of_hearts.png'),
      name: "Herz Fünf",
    },
    {
      value: 5,
      color: "diamonds",
      imagePath: require('../assets/cards/5_of_diamonds.png'),
      name: "Karo Fünf",
    },
    {
      value: 5,
      color: "clubs",
      imagePath: require('../assets/cards/5_of_clubs.png'),
      name: "Kreuz Fünf",
    },
    {
      value: 5,
      color: "spades",
      imagePath: require('../assets/cards/5_of_spades.png'),
      name: "Pik Fünf",
    },
    {
      value: 6,
      color: "hearts",
      imagePath: require('../assets/cards/6_of_hearts.png'),
      name: "Herz Sechs",
    },
    {
      value: 6,
      color: "diamonds",
      imagePath: require('../assets/cards/6_of_diamonds.png'),
      name: "Karo Sechs",
    },
    {
      value: 6,
      color: "clubs",
      imagePath: require('../assets/cards/6_of_clubs.png'),
      name: "Kreuz Sechs",
    },
    {
      value: 6,
      color: "spades",
      imagePath: require('../assets/cards/6_of_spades.png'),
      name: "Pik Sechs",
    },
    {
      value: 7,
      color: "hearts",
      imagePath: require('../assets/cards/7_of_hearts.png'),
      name: "Herz Sieben",
    },
    {
      value: 7,
      color: "diamonds",
      imagePath: require('../assets/cards/7_of_diamonds.png'),
      name: "Karo Sieben",
    },
    {
      value: 7,
      color: "clubs",
      imagePath: require('../assets/cards/7_of_clubs.png'),
      name: "Kreuz Sieben",
    },
    {
      value: 7,
      color: "spades",
      imagePath: require('../assets/cards/7_of_spades.png'),
      name: "Pik Sieben",
    },
    {
      value: 8,
      color: "hearts",
      imagePath: require('../assets/cards/8_of_hearts.png'),
      name: "Herz Acht",
    },
    {
      value: 8,
      color: "diamonds",
      imagePath: require('../assets/cards/8_of_diamonds.png'),
      name: "Karo Acht",
    },
    {
      value: 8,
      color: "clubs",
      imagePath: require('../assets/cards/8_of_clubs.png'),
      name: "Kreuz Acht",
    },
    {
      value: 8,
      color: "spades",
      imagePath: require('../assets/cards/8_of_spades.png'),
      name: "Pik Acht",
    },
    {
      value: 9,
      color: "hearts",
      imagePath: require('../assets/cards/9_of_hearts.png'),
      name: "Herz Neun",
    },
    {
      value: 9,
      color: "diamonds",
      imagePath: require('../assets/cards/9_of_diamonds.png'),
      name: "Karo Neun",
    },
    {
      value: 9,
      color: "clubs",
      imagePath: require('../assets/cards/9_of_clubs.png'),
      name: "Kreuz Neun",
    },
    {
      value: 9,
      color: "spades",
      imagePath: require('../assets/cards/9_of_spades.png'),
      name: "Pik Neun",
    },
    {
      value: 10,
      color: "hearts",
      imagePath: require('../assets/cards/10_of_hearts.png'),
      name: "Herz Zehn",
    },
    {
      value: 10,
      color: "diamonds",
      imagePath: require('../assets/cards/10_of_diamonds.png'),
      name: "Karo Zehn",
    },
    {
      value: 10,
      color: "clubs",
      imagePath: require('../assets/cards/10_of_clubs.png'),
      name: "Kreuz Zehn",
    },
    {
      value: 10,
      color: "spades",
      imagePath: require('../assets/cards/10_of_spades.png'),
      name: "Pik Zehn",
    },
    {
      value: 11,
      color: "hearts",
      imagePath: require('../assets/cards/jack_of_hearts2.png'),
      name: "Herz Bube",
    },
    {
      value: 11,
      color: "diamonds",
      imagePath: require('../assets/cards/jack_of_diamonds2.png'),
      name: "Karo Bube",
    },
    {
      value: 11,
      color: "clubs",
      imagePath: require('../assets/cards/jack_of_clubs2.png'),
      name: "Kreuz Bube",
    },
    {
      value: 11,
      color: "spades",
      imagePath: require('../assets/cards/jack_of_spades2.png'),
      name: "Pik Bube",
    },
    {
      value: 12,
      color: "hearts",
      imagePath: require('../assets/cards/queen_of_hearts2.png'),
      name: "Herz Dame",
    },
    {
      value: 12,
      color: "diamonds",
      imagePath: require('../assets/cards/queen_of_diamonds2.png'),
      name: "Karo Dame",
    },
    {
      value: 12,
      color: "clubs",
      imagePath: require('../assets/cards/queen_of_clubs2.png'),
      name: "Kreuz Dame",
    },
    {
      value: 12,
      color: "spades",
      imagePath: require('../assets/cards/queen_of_spades2.png'),
      name: "Pik Dame",
    },
    {
      value: 13,
      color: "hearts",
      imagePath: require('../assets/cards/king_of_hearts2.png'),
      name: "Herz König",
    },
    {
      value: 13,
      color: "diamonds",
      imagePath: require('../assets/cards/king_of_diamonds2.png'),
      name: "Karo König",
    },
    {
      value: 13,
      color: "clubs",
      imagePath: require('../assets/cards/king_of_clubs2.png'),
      name: "Kreuz König",
    },
    {
      value: 13,
      color: "spades",
      imagePath: require('../assets/cards/king_of_spades2.png'),
      name: "Pik König",
    },
    {
      value: 14,
      color: "hearts",
      imagePath: require('../assets/cards/ace_of_hearts.png'),
      name: "Herz Ass",
    },
    {
      value: 14,
      color: "diamonds",
      imagePath: require('../assets/cards/ace_of_diamonds.png'),
      name: "Karo Ass",
    },
    {
      value: 14,
      color: "clubs",
      imagePath: require('../assets/cards/ace_of_clubs.png'),
      name: "Kreuz Ass",
    },
    {
      value: 14,
      color: "spades",
      imagePath: require('../assets/cards/ace_of_spades.png'),
      name: "Pik Ass",
    },    
  ],
};
