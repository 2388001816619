import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, FlatList, Image, TouchableOpacity, Dimensions, Modal, PanResponder, Animated, Platform } from 'react-native';
import LottieView from "lottie-react-native";
import { LinearGradient } from 'expo-linear-gradient';

import constants from '../config/constants';
import Button from '../components/Button';

export default function GameScreen ({ navigation, route }) {

  const numRow = 3;
  const player = route.params.player;
  const [ cardChoice, setCardChoice ] = useState(null);
  const [ cards, setCards ] = useState([]);
  const [ otherCards, setOtherCards ] = useState([]);
  const [ showModal, setShowModal ] = useState(false);
  const [ win, setWin ] = useState();
  const [ round, setRound ] = useState(1);
  const [ score1, setScore1 ] = useState(0);
  const [ score2, setScore2 ] = useState(0);
  const [ turn, setTurn ] = useState(true);
  const [ error, setError ] = useState(false);

  useEffect(() => {
    startGame();  
  }, []);

  const startGame = () => {
    setCardChoice(null);
    setOtherCards([]);
    setCards([]);
    if(cardChoice == null && cards.length == 0 && otherCards.length == 0) {
      setError(false);
      var tempCard = [];
      var pickedCards = [];
      var rounds = numRow
      for (let a = 0; a < rounds; a++) {
        var index = Math.floor(Math.random() * constants.cards.length);
        var contin = true;
        for (let b = 0; b < pickedCards.length; b++) {
          if (pickedCards[b] == index) {
            contin = false;
            rounds++;
          }
        }
        if(contin) {
          pickedCards.push(index);
          tempCard.push(constants.cards[index]);
          tempCard[tempCard.length-1].show = false;
        }
      }
      setCards(tempCard);
    } else {
      setError(true);
    }
  }

  const cardClick = (item) => {
    if(cardChoice == null) {
      setCardChoice(item.value);
    } else {
      setOtherCards((prevStates) => {
        prevStates.push(item.value);
        return [...prevStates];
      });
      if(otherCards.length + 1 == numRow)
      checkWin();
    }
    for (let a = 0; a < cards.length; a++) {
      if (cards[a].name == item.name) {
        cards[a].show = true;
      }
    }
    setCards((prevStates) => {
      return [...prevStates];
    });
    
  }

  const checkWin = () => {
    var win = true;
    for (let a = 0; a < otherCards.length; a++) {
      if(cardChoice < otherCards[a]) {
        win = false;
      }
    }
    setCardChoice(null);
    if(win) {
      setWin(true);
      if(turn) {
        setScore1(score1+1);
      } else {
        setScore2(score2+1);
      }
    } else {
      setWin(false);
      if(!turn) {
        setScore1(score1+1);
      } else {
        setScore2(score2+1);
      }
    }
    setShowModal(true);
  }

  const replay = () => {
    startGame();
  }

  const closeModal = () => {
    setShowModal(false);
    if(player !== 1) {
      setTurn(!turn);
    }  
    setRound(round+1);
  }


  return (    
    <View>
      <View style={{flexDirection: "row", textAlign: "center"}}>
        <View style={{backgroundColor: turn ? "red" : "lightgray", padding: 5, paddingHorizontal: 10}}><Text style={{fontSize: 20}}>Spieler 1</Text><Text style={styles.score}>{score1}</Text></View>
        <View style={{flex: 1, justifyContent: 'center'}}><Text style={{fontSize: 40, textAlign: "center"}}>Runde {round}</Text></View>
        <View style={{backgroundColor: !turn ? "red" : "lightgray", padding: 5, paddingHorizontal: 10}}><Text style={{fontSize: 20}}>{player == 2 ? "Spieler 2" : "Computer"}</Text><Text style={styles.score}>{score2}</Text></View>
      </View>
      <View style={{ backgroundColor: "transparent", width: "100%", textAlign: "center", paddingVertical: 10}}>
      {!cards.length == 0 && <>{ cardChoice ? <Text style={styles.header}>Decke die anderen Karten auf</Text> : <Text style={styles.header}>Wähle die höchste Karte aus</Text> }</>}
      </View>
      <FlatList
        data={cards}
        extraData={cards}
        keyExtractor={item => item.name}
        numColumns={numRow}
        style={{marginVertical: 20}}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={item.show ? null : () => cardClick(item)}>
            <Image source={item.show ? item.imagePath : constants.cardBackground} style={{width: Dimensions.get('window').width / numRow, height: Dimensions.get('window').width / numRow / 20 * 29}} />
          </TouchableOpacity>
        )}
      />
    
      {(!cardChoice && !showModal) && <Button title={!error ? "neue runde" : "mischen"} onPress={cardChoice ? null : () => replay()} color={cardChoice ? "gray" : "red"}/>}
      <Modal
        animationType="fade"
        visible={showModal}
        transparent={true}
      >
        {Platform.OS !== 'web' && 
          <LottieView
            autoPlay
            loop={false}
            source={ win ? require("../assets/animations/74680-confetti.json") : require("../assets/animations/947-drink.json")}
                speed={0.5}
          />
        }
        <View style={{flex: 0.9,justifyContent: 'center',}} >
          <LinearGradient colors={["transparent", "white", "transparent"]} start={{ x: 0, y: 1 }} end={{ x: 1, y: 1 }}>
            <Text style={{textAlign: 'center',fontSize: 70,}}>{win ? "Gewonnen!" : "Trink!"}</Text>
          </LinearGradient>
        </View>
        <Button title={"Weiter"} onPress={() => closeModal()} color={"red"}/>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    fontSize: 25,
    textAlign: "center",
  },
  score: {
    fontSize: 70,
    fontWeight: 'bold',
    textAlign: "center",
  },
});


