import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from '@react-navigation/native';

import routes from "./routes";
import HeaderBranding from "../components/HeaderBranding";

import WelcomeScreen from "../screens/WelcomeScreen";
import AboutScreen from "../screens/AboutScreen";
import RulesScreen from "../screens/RulesScreen";
import GameScreen from "../screens/GameScreen";

const Stack = createStackNavigator();

const StackNavigator = () => (
  <NavigationContainer>
    <Stack.Navigator
      initialRouteName={routes.WELCOME}
      screenOptions={{
        headerRight: () => (<HeaderBranding />),
      }}
    >
      <Stack.Screen 
        name={routes.WELCOME} 
        component={WelcomeScreen} 
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen name={routes.GAME} component={GameScreen} options={{ title: 'The Door A Game' }} />
      <Stack.Screen name={routes.RULE} component={RulesScreen} options={{ title: 'Regeln' }} />
      <Stack.Screen name={routes.ABOUT} component={AboutScreen} options={{ title: 'Info' }} />
    </Stack.Navigator>
  </NavigationContainer>
);

export default StackNavigator;
