import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

export default function RulesScreen (props) {
  return (    
    <LinearGradient
      colors={["red", "white"]}
      style={{ flex: 1, padding: 20 }}
    >
      <Text style={{textAlign: "center", fontWeight: "bold", fontSize: 50}}>Spielregeln</Text>
      <Text style={styles.paragraph}>Vor dem Spiel werden 3 zufällige karten aus einem Standard Pokerdeck ohne Joker ausgewählt.</Text>
      <Text style={styles.paragraph}>Aus diesen 3 Karten muss der Spieler, der gerade an der Reihe ist, die Karte auswählen, die seiner Meinung nach die Höchste ist. Dabei ist die Kartenwertigkeit aufsteigend: Zahlwert der Karte (2-10), Bube (J), Dame (Q), König (K), Ass (A).</Text>
      <Text style={styles.paragraph}>Nachdem dieser die Auswahl getroffen hat, muss er auch die restlichen Karten aufdecken, um zu sehen, ob er wirklich die höchste karte gewählt hat. Falls die Gewählte karte gleich hoch mit einer verdeckten karte ist (quasi unentschieden) gewinnt der Spieler dennoch.</Text>
      <Text style={styles.paragraph}>Gewinnt der Spieler wird ihm ein Punkt gutgeschrieben. Verliert der Spieler bekommt der Gegner den Punkt und der gedemütigte Spieler muss trinken.</Text>
      <Text style={{...styles.paragraph, textAlign: "center", fontWeight: "bold"}}>Idea by 🚪🅰 developed by ✝📴</Text>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  paragraph: {
    marginVertical: 10,
  },
});
